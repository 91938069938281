<template>
    <div id="search">
        <div id='title'>
            <h1>Welcome to HUSCH</h1>
            <!-- <div id="body">
                <img src="@/assets/human7.png" alt="" style="max-width: 100%; max-height: 100%">
            </div> -->
            <div>
                <h3 style="line-height: 20px;;">Human Universal Single Cell Hub (HUSCH) is a scRNA-seq database for
                    analyzing and visualizing human gene expression in different tissues. The HUSCH database consists
                    185
                    datasets from 45 human tissues covering 7 different platforms. HUSCH provides detailed cell-type
                    annotation, expression visualization, marker gene identification, functional analyses, transcription
                    factor, and cell-cell interaction analyses for each dataset, and also enables online cell-type
                    annotation based on the curated reference.
                </h3>
            </div>
        </div>
        <div id='cont'>
            <el-card>
                <el-divider content-position="left"><span style="color: rgb(26, 96, 67) ;">Tissue List</span>
                </el-divider>
                <div>
                    <el-radio v-model="radio" label="1">View by Body</el-radio>
                    <el-radio v-model="radio" label="2">View by System</el-radio>
                    <el-radio v-model="radio" label="3">View by Alphabet</el-radio>
                </div>
                <div id="tissue">
                    <div id="tissue_list">
                        <div id="system" v-if="radio==1">
                            <el-collapse v-model="activeNames" @change="handleChange">
                                <el-collapse-item title="Head and Neck" name="1">
                                    <div id="system-list">
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 8</p>
                                                    <p>CellNumber: 79895</p>
                                                    <p>Celltype: Astrocyte,B,CD4T,CD8T,Dopaminergic
                                                        Neuron,Endothelial,Ependymal,Epithelial,Excitatory
                                                        Neuron,GABAergic
                                                        Neuron,Granule,Inhibitory
                                                        Neuron,Interneuron,Microglia,Mono/Macro,Muscle,Neutrophil,NK,Oligodendrocyte,OPC,Pericyte,Plasma,Purkinje
                                                        Neuron,Treg</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Cerebellum">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Cerebellum.png" alt="">Cerebellum</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 8</p>
                                                    <p>CellNumber: 79895</p>
                                                    <p>Celltype: Astrocyte,B,CD4T,CD8T,Dopaminergic
                                                        Neuron,Endothelial,Ependymal,Epithelial,Excitatory
                                                        Neuron,GABAergic
                                                        Neuron,Granule,Inhibitory
                                                        Neuron,Interneuron,Microglia,Mono/Macro,Muscle,Neutrophil,NK,Oligodendrocyte,OPC,Pericyte,Plasma,Purkinje
                                                        Neuron,Treg</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Cerebral-Cortex">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Cerebral-Cortex.png" alt="">Cerebral
                                                        Cortex</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 8</p>
                                                    <p>CellNumber: 79895</p>
                                                    <p>Celltype: Astrocyte,B,CD4T,CD8T,Dopaminergic
                                                        Neuron,Endothelial,Ependymal,Epithelial,Excitatory
                                                        Neuron,GABAergic
                                                        Neuron,Granule,Inhibitory
                                                        Neuron,Interneuron,Microglia,Mono/Macro,Muscle,Neutrophil,NK,Oligodendrocyte,OPC,Pericyte,Plasma,Purkinje
                                                        Neuron,Treg</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Cerebrospinal-Fluid">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Cerebrospinal-Fluid.png"
                                                            alt="">Cerebrospinal Fluid</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 1</p>
                                                    <p>CellNumber : 9839</p>
                                                    <p>Celltype :
                                                        B,CD4T,CD8T,Endothelial,Fibroblast,Macrophage,Melanocyte,Pericyte,Photoreceptor/RPE,Red
                                                        Blood Cell(RBC),Schwann,Smooth Muscle</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Choroid">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Eye.png" alt="">Choroid</div>
                                                </router-link>
                                            </el-popover>

                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 1</p>
                                                    <p>CellNumber: 30507</p>
                                                    <p>Celltype: Chondral
                                                        Stem,Chondrocyte,Endothelial,Mast,PVC,SC,Skeletal Stem</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Ear">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Ear.png" alt="">Ear</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 7</p>
                                                    <p>CellNumber: 184158</p>
                                                    <p>Celltype: Amacirne,Amacrine,Astrocyte,B,Bipolar,Blood
                                                        Vesel,CD4T,CD8T,Cone,Cone
                                                        Off BC,Cone On BC/Rod BC,Conjunctival Basal Epithelium,Corneal
                                                        Basal
                                                        Epithelium,Corneal Endothelium,Corneal
                                                        Stromal,Endothelial,Erythrocyte,Fibroblast,Fibroblastic Corneal
                                                        Endothelial,Ganglion,Horizontal,Limbal Epithelial,Limbal
                                                        Fibroblast,Limbal
                                                        Neural Crest Progenitor,Limbal Progenitor,Limbal Stroma
                                                        Keratocyte,Limbal
                                                        Superficial Epithelium,Limbal Suprabasal,Lymphatic
                                                        Endothelial,Lymphatic
                                                        Vessel,Mast,Melanocyte,Microglia,Mono/Macro,Muller,NK,Pericyte,Photoreceptor,Retinal
                                                        Ganglion,Rod,RPE,Schwann,Smooth Muscle,Stromal,Vascular
                                                        Endothelial</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Eye">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Eye.png" alt="">Eye</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 8</p>
                                                    <p>CellNumber: 79895</p>
                                                    <p>Celltype: Astrocyte,B,CD4T,CD8T,Dopaminergic
                                                        Neuron,Endothelial,Ependymal,Epithelial,Excitatory
                                                        Neuron,GABAergic
                                                        Neuron,Granule,Inhibitory
                                                        Neuron,Interneuron,Microglia,Mono/Macro,Muscle,Neutrophil,NK,Oligodendrocyte,OPC,Pericyte,Plasma,Purkinje
                                                        Neuron,Treg</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Midbrain">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Midbrain.png" alt="">Midbrain</div>
                                                </router-link>
                                            </el-popover>

                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 2</p>
                                                    <p>CellNumber: 46896</p>
                                                    <p>Celltype: B,Basal,Bowman’s
                                                        Gland,CD4T,CD8T,Club,DC,Fibroblast,Globose
                                                        Basal,Goblet,Macrophage,Mast,Monocyte,Multiciliated,Neuron,NK,OEC,Olfactory
                                                        Horizontal Basal,Olfactory
                                                        Microvillar,Pericyte,Plasma,Respiratory
                                                        Ciliated,Respiratory Horizontal Basal,Smooth
                                                        Muscle,Suprabasal,Sustentacular</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Nose">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Nose.png" alt="">Nose</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 2</p>
                                                    <p>CellNumber: 12259</p>
                                                    <p>Celltype:
                                                        B,CD4T,CD8T,DC,Endothelial,Fibroblast,Mono/Macro,Muscle,Neutrophil,Plasma,Thyrocyte
                                                    </p>
                                                </div>
                                                <router-link slot="reference" to="info_tissue/Thyroid">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Thyroid.png" alt="">Thyroid</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                    </div>
                                </el-collapse-item>
                                <el-collapse-item title="Gut" name="2">
                                    <div id="system-list">
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 2</p>
                                                    <p>CellNumber: 16187</p>
                                                    <p>Celltype:
                                                        B,CD4T,CD8T,Chromoblast,Endothelial,Fibroblast,Macrophage,Mono/Macro,Muscle,Neuron,Neutrophil,NK,Stromal-like,Zona
                                                        Fasciculata</p>
                                                </div>
                                                <router-link slot="reference" to="info_tissue/Adrenal-Gland">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Adrenal-Gland.png" alt="">
                                                        <p style="width:58%">Adrenal Gland</p>
                                                    </div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 3</p>
                                                    <p>CellNumber: 21167</p>
                                                    <p>Celltype: B,Basal,Basal
                                                        Epithelial,Brush/PNEC,CD4T,CD8T,Chondrocyte,Ciliated,DC,Endothelial,Fibroblast,Goblet,Mono/Macro,Muscle,NK,Plasma,Secretory,Smooth
                                                        Muscle</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Airway">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Airway.png" alt="">
                                                        <p style="width:58%">Airway</p>
                                                    </div>
                                                </router-link>
                                            </el-popover>

                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 1</p>
                                                    <p>CellNumber : 9652</p>
                                                    <p>Celltype :
                                                        B,Endothelial,Epithelial,Fibroblast,Macrophage,Mast,Muscle,Myofibroblast,T
                                                    </p>
                                                </div>
                                                <router-link slot="reference" to="info_tissue/Artery">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Artery.png" alt="">
                                                        <p style="width:58%">Artery</p>
                                                    </div>
                                                </router-link>
                                            </el-popover>

                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 4</p>
                                                    <p>CellNumber : 17072</p>
                                                    <p>Celltype:
                                                        B,Basal,CD4T,CD8T,Endothelial,Fibroblast,Intermediate(Umbrella-Basal),Interstitial,Macrophage,Mast,Monocyte,Neutrophil,NK,Smooth
                                                        Muscle,T_Unknown,TNNT1+ Epithelial,Umbrella,Urothelial</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Bladder">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Bladder.png" alt="">Bladder</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 4</p>
                                                    <p>CellNumber: 80562</p>
                                                    <p>Celltype: B,Basal,CD8T,Fibroblast,Luminal,Luminal
                                                        Progenitor,Lymphatic
                                                        Endothelial,Mature Luminal,Mono/Macro,NK,Pericyte,Vascular
                                                        Endothelial</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Breast">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Breast.png" alt="">Breast</div>
                                                </router-link>
                                            </el-popover>

                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 1</p>
                                                    <p>CellNumber : 11024</p>
                                                    <p>Celltype :
                                                        B,CD4T,CD8T,Endothelial,Fibroblast,Macrophage,Melanocyte,Pericyte,Photoreceptor/RPE,Red
                                                        Blood Cell(RBC),Schwann,Smooth Muscle</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Common-Bile-Duct">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Common-Bile-Duct.png" alt="">Common
                                                        Bile Duct</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 3</p>
                                                    <p>CellNumber: 19821</p>
                                                    <p>Celltype: B,Basal,Columnar
                                                        Epithelial,Endothelial,Epithelial,Fibroblast,Goblet,Highly
                                                        Proliferative
                                                        Squamous Epithelial,Keratinocyte,Lymphatic
                                                        Endothelial,Mast,Mono/Macro,Muscle,Neutrophil,Smooth
                                                        Muscle,Squamous Epithelial
                                                    </p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Esophagus">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Esophagus.png" alt="">Esophagus</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 2</p>
                                                    <p>CellNumber : 13936</p>
                                                    <p>Celltype:
                                                        B,CD4T,CD8T,Cholangiocyte,DC,Endothelial,Epithelial,Fibroblast,Mast,Mono/Macro,Muscle,Neutrophil
                                                    </p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Gall-Bladder">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Gall-Bladder.png" alt="">Gall Bladder
                                                    </div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 3</p>
                                                    <p>CellNumber: 10504</p>
                                                    <p>Celltype:
                                                        CD8T,DC,Endothelial,Fibroblast,Mast,Mono/Macro,Muscle,Neutrophil,NK,Smooth
                                                        Muscle</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Heart">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Heart.png" alt="">Heart</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 9</p>
                                                    <p>CellNumber: 83462</p>
                                                    <p>Celltype: CNT,Collecting Duct,Connecting Tubule,Distal
                                                        Tubule,Epithelial,Epithelial||renal Pelvis,Glomerular,Glomerular
                                                        Parietal
                                                        Epithelial,Glomerular Visceral Epithelial,Intercalated,Loop Of
                                                        Henle,Parietal
                                                        Epithelial,Podocyte,Principal,Proximal Convoluted Tubule
                                                        ,Proximal Straight
                                                        Tubule,Proximal Tubule,Renal Alpha-intercalated,Renal
                                                        Beta-intercalated,Stem,Thick Ascending Limb</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Kidney">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Kidney.png" alt="">Kidney</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 17</p>
                                                    <p>CellNumber : 137194</p>
                                                    <p>Celltype :
                                                        Absorptive,B,CD4T,CD8T,DC,Endothelial,Enterocyte,Enterocyte
                                                        Progenitor,Enteroendocrine,Epithelial,Fibroblast,Glial,Goblet,Goblet
                                                        Progenitor,ILC,Intraepithelial
                                                        Lymphocyte,M,Mast,Mesenchymal,Mono/Macro,Mucous
                                                        Neck,Muscle,Myofibroblast,Neuron,NK,Paneth,Paneth-like,Pericyte,Plasma,Schwann,Smooth
                                                        Muscle,Stem,Transit Amplifying,Treg,Tuft</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Large-Intestine">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Large-Intestine.png" alt="">Large
                                                        Intestine</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 7</p>
                                                    <p>CellNumber: 59993</p>
                                                    <p>Celltype: B,CD4T,CD8T,Central Venous
                                                        LSEC,Cholangiocyte,DC,Endothelial,Epithelial,Erythrocyte,Hepatic
                                                        Oval,Hepatocyte,Kupffer,Mast,Mesenchymal,Mono/Macro,Muscle,Neutrophil,NK,Periportal
                                                        LSEC,Plasma,Portal Endothelial,Smooth Muscle,Treg</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Liver">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Liver.png" alt="">Liver</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 12</p>
                                                    <p>CellNumber: 108428</p>
                                                    <p>Celltype: Alveolar Bipotent Progenitor,Alveolar Type 1,Alveolar
                                                        Type
                                                        2,B,Basal,CD4T,CD8T,Chondrocyte,Ciliated,Club,DC,Endothelial,Fibroblast,Goblet,Intermediate(Club-Basal),Ionocyte,Lymphatic
                                                        Endothelial,Mast,Megakaryocyte,Mono/Macro,Mucous,Muscle,Myofibroblast,Neuroendocrine,Neutrophil,NK,Secretory
                                                        Epithelial,Smooth Muscle</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Lung">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Lung.png" alt="">Lung</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 1</p>
                                                    <p>CellNumber: 7770</p>
                                                    <p>Celltype: B,CD4T,CD8T,Mono/Macro,Treg</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Lymph-Node">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Lymph-Node.png" alt="">Lymph Node</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 4</p>
                                                    <p>CellNumber: 21812</p>
                                                    <p>Celltype:
                                                        Acinar,Alpha,APC,Beta,Delta,Duct,Endocrine,Endothelial,Epsilon,Exocrine,Fibroblast,Mast,Mesenchymal,Mono/Macro,Muscle,Pancreatic
                                                        Polypeptide,Pancreatic Stellate,Schwann</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Pancreas">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Pancreas.png" alt="">Pancreas</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 17</p>
                                                    <p>CellNumber : 137194</p>
                                                    <p>Celltype :
                                                        Absorptive,B,CD4T,CD8T,DC,Endothelial,Enterocyte,Enterocyte
                                                        Progenitor,Enteroendocrine,Epithelial,Fibroblast,Glial,Goblet,Goblet
                                                        Progenitor,ILC,Intraepithelial
                                                        Lymphocyte,M,Mast,Mesenchymal,Mono/Macro,Mucous
                                                        Neck,Muscle,Myofibroblast,Neuron,NK,Paneth,Paneth-like,Pericyte,Plasma,Schwann,Smooth
                                                        Muscle,Stem,Transit Amplifying,Treg,Tuft</p>
                                                </div>
                                                <router-link slot="reference" to="info_tissue/Small-Intestine">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Small-Intestine.png" alt="">Small
                                                        Intestine</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 2</p>
                                                    <p>CellNumber: 19957</p>
                                                    <p>Celltype: B,CD4T,CD8T,Endothelial,Erythrocyte,Lymphoid
                                                        Progenitor,Mono/Macro,Neutrophil,NK,Plasma,Treg</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Spleen">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Spleen.png" alt="">Spleen</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 4</p>
                                                    <p>CellNumber: 17808</p>
                                                    <p>Celltype:
                                                        B,CD4T,CD8T,Chief,Chromaffin,Endothelial,Epithelial,Fibroblast,FibSmo,Gastric
                                                        Pit,Granulocyte,Mast,Mono/Macro,Mucous,NK,Parietal,Peptic,Pit
                                                        Mucosal
                                                        Epithelial,Plasma,Smooth Muscle</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Stomach">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Stomach.png" alt="">Stomach</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 1</p>
                                                    <p>CellNumber: 59857</p>
                                                    <p>Celltype: Apical/Narrow,Basal,Clear,Efferent
                                                        Duct,Endothelial,Germ,Leydig,Mono/Macro,Peritubular
                                                        Myoid,Principal,Sertoli,Sperm,Spermatid,Spermatocyte,Spermatogonia,Spermatogonial
                                                        Stem,Stromal,Vascular Smooth Muscle</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Thymus">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Thymus.png" alt="">Thymus</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                    </div>
                                </el-collapse-item>
                                <el-collapse-item title="Reproductive" name="3">
                                    <div id="system-list">
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 2</p>
                                                    <p>CellNumber: 27674</p>
                                                    <p>Celltype: B,CD4T,CD8T,Endothelial,Epithelial,Fibroblast,Lymphatic
                                                        Endothelial,Mast,Mono/Macro,NK,Smooth Muscle,Vascular
                                                        Endothelial,Vascular
                                                        Smooth Muscle</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Cervix">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Cervix.png" alt="">Cervix</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 4</p>
                                                    <p>CellNumber: 54801</p>
                                                    <p>Celltype: B,CD4T,CD8T,DC,Endothelial,Extravillous
                                                        Trophoblast,Fibroblast,Glandular
                                                        Epithelial,Granulocyte,Hofbauer,ILC,Mast,Mono/Macro,NK,Pericyte,Perivascular,Plasma,Syncytiotrophoblast,Treg,Villous
                                                        Cytotrophoblast</p>
                                                </div>
                                                <router-link slot="reference" to="info_tissue/Decidua">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Decidua.png" alt="">Decidua</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 1</p>
                                                    <p>CellNumber: 4500</p>
                                                    <p>Celltype:
                                                        B,CD4T,CD8T,Ciliated,DC,Endothelial,Epithelial,Fibroblast,Mast,Mono/Macro,Muscle,Neutrophil
                                                    </p>
                                                </div>
                                                <router-link slot="reference" to="info_tissue/Fallopian">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Fallopian.png" alt="">Fallopian</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 3</p>
                                                    <p>CellNumber: 52641</p>
                                                    <p>Celltype:
                                                        B,CD4T,CD8T,Endothelial,Erythrocyte,Fibroblast,Germ,Granulosa,Mono/Macro,NK,Smooth
                                                        Muscle,Theca</p>
                                                </div>
                                                <router-link slot="reference" to="info_tissue/Ovary">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Ovary.png" alt="">Ovary</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 5</p>
                                                    <p>CellNumber: 64984</p>
                                                    <p>Celltype:
                                                        APC,CD4T,Cytotrophoblast,DC,Decidual,Endothelial,Epithelial,Epithelial
                                                        Glandular,Erythrocyte,Fibroblast,Granulocyte,Hofbauer,Mono/Macro,Muscle,Neutrophil,Proliferating,Syncytiotrophoblast,Trophoblast,Vascular
                                                        Smooth Muscle</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Placenta">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Placenta.png" alt="">Placenta</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 4</p>
                                                    <p>CellNumber: 193376</p>
                                                    <p>Celltype:
                                                        Basal,CD4T,Club,Endothelial,Epithelial,Fibroblast,Hillock,Leukocyte,Luminal,Mono/Macro,Muscle,Neuroendocrine,Neutrophil,NK,Smooth
                                                        Muscle,Stress</p>
                                                </div>
                                                <router-link slot="reference" to="info_tissue/Prostate">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Prostate.png" alt="">Prostate</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 4</p>
                                                    <p>CellNumber : 59857</p>
                                                    <p>Celltype : Apical/Narrow,Basal,Clear,Efferent
                                                        Duct,Endothelial,Germ,Leydig,Macrophage,Peritubular Myoid
                                                        Cell(PTM),Principal,Sertoli,Sperm,Spermatid,Spermatocyte,Spermatogonia,Spermatogonial
                                                        Stem cell(SSC),Stromal,Vascular Smooth Muscle</p>
                                                </div>
                                                <router-link slot="reference" to="info_tissue/Testis">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Testis.png" alt="">Testis</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 1</p>
                                                    <p>CellNumber : 1886</p>
                                                    <p>Celltype: B,Basal,Fibroblast,Mono/Macro,Muscle,NK,Superficial</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Ureter">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Prostate.png" alt="">Ureter</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 1</p>
                                                    <p>CellNumber: 77478</p>
                                                    <p>Celltype:
                                                        CD8T,Endometrial,Endothelial,Epithelial,Fibroblast,Mono/Macro,Muscle,NK,Smooth
                                                        Muscle</p>
                                                </div>
                                                <router-link slot="reference" to="info_tissue/Uterus">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Uterus.png" alt="">Uterus</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                    </div>
                                </el-collapse-item>
                                <el-collapse-item title="Support System" name="4">
                                    <div id="system-list">
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 9</p>
                                                    <p>CellNumber: 159167</p>
                                                    <p>Celltype: Adipocyte,Adipocyte
                                                        Progenitor,APC,B,CD4T,CD8T,Chondrocyte,Classic
                                                        Endothelial,DC,Endothelial,Fibroblast,Granulocyte,Intermediate,Lymphatic
                                                        Endothelial,Mast,Mesothelial,Microvascular
                                                        Endothelial,Mono/Macro,Neutrophil,NK,Pericyte,Preadipocyte,Smooth
                                                        Muscle,Stem
                                                    </p>
                                                </div>
                                                <router-link slot="reference" to="info_tissue/Adipose">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Adipose.png" alt="">
                                                        <p style="width:58%">Adipose</p>
                                                    </div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 21</p>
                                                    <p>CellNumber : 483286</p>
                                                    <p>Celltype :
                                                        B,CD4T,CD8T,DC,Mast,Megakaryocyte,Mono/Macro,Myofibroblast,Neutrophil,NK,pDC,Plasma,Treg
                                                    </p>
                                                </div>
                                                <router-link slot="reference" to="info_tissue/Blood">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Blood.png" alt="">Blood</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 12</p>
                                                    <p>CellNumber : 152656</p>
                                                    <p>Celltype : B,B/NK
                                                        Progenitor,Basophil,CD4T,CD8T,CMP,DC,Erythrocyte,Erythrocyte
                                                        Progenitor,GMP,HSC,HSPC,Lymphoid
                                                        Progenitor,Mast,Megakaryocyte,MEP,MLP,Mono/Macro,MPP,Multiple
                                                        Progenitor,Neutrophil,NK,Plasma,Pre B,T,T Cell Progenitor</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Bone-Marrow">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Bone-Marrow.png" alt="">Bone Marrow
                                                    </div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 1</p>
                                                    <p>CellNumber : 18625</p>
                                                    <p>Celltype : B,B/NK Progenitor,Basophil,CD4T,CD8T,Common myeloid
                                                        progenitor(CMP),Dendritic
                                                        cell(DC),EPC,Erythroblast,Erythroid,Granulocyte_monocyte
                                                        progenitor(GMP),Hematopoietic stem cell(HSC),Hemopoietic
                                                        stem/progenitor
                                                        cell(HSPC),Lymphoid Progenitor,Lymphoid-primed multipotent
                                                        progenitor(LMPP),MPP,Macrophage,Megakaryocyte-erythroid
                                                        progenitor(MEP),Monocyte,Multi-lymphoid progenitor(MLP),Natural
                                                        killer
                                                        cell(NK),Neutrophil,T Progenitor</p>
                                                </div>
                                                <router-link slot="reference" to="info_tissue/Bone">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Bone.png" alt="">Bone</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 4</p>
                                                    <p>CellNumber: 33339</p>
                                                    <p>Celltype:
                                                        Adipocyte,B,CD4T,CD8T,DC,Endothelial,Fibroblast,Macrophage,Mast,Muscle
                                                        Progenitor,Myeloid,Neutrophil,NK,PiTendon
                                                        Progenitor,Satellite,Smooth
                                                        Muscle,T,T_Unknown,Tendon Progenitor,Tendon Progenitor Stem</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Muscle">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Muscle.png" alt="">Muscle</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 10</p>
                                                    <p>CellNumber: 118799</p>
                                                    <p>Celltype: Basal,Bulge,CD4T,CD8T,Chondrocyte,Corneum,DC,Eccrine
                                                        Duct,Eccrine
                                                        Gland,Endothelial,Epidermal Stem,Epidermis,Fibroblast,IFE
                                                        Basal,IFE Granular,IFE
                                                        Mitotic,IFE
                                                        Spinous,Infundibulum,IRS/Cuticle,Isthmus,Keratinocyte,Langerhans,Lymph
                                                        Endothelial,Mast,Matrix/Cortex/Medulla,Melanocyte,Mesenchymal,Mono/Macro,Myofibroblast,Neural,NK,ORS
                                                        B,Pericyte,Schwann,Sebaceous/Apocrine,Secretory
                                                        Epithelial,Smooth
                                                        Muscle,Spinous,Sweat Gland,Treg,Vasc Endotheli
                                                        al</p>
                                                </div>
                                                <router-link slot="reference" to="info_tissue/Skin">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Skin.png" alt="">Skin</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 1</p>
                                                    <p>CellNumber : 17198</p>
                                                    <p>Celltype :
                                                        B,CD4T,CD8T,Endothelial,Fibroblast,Mesothelial,Mononuclear
                                                        Phagocyte,Myofibroblast</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Peritoneum">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Peritoneum.png" alt="">Peritoneum</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 1</p>
                                                    <p>CellNumber: 9794</p>
                                                    <p>Celltype: Fibroblast,Mesothelial,Mono/Macro,Neutrophil,NK</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Pleura">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Pleura.png" alt="">Pleura</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                        </div>
                        <div id="system" v-if="radio==2">
                            <el-collapse v-model="activeNames" @change="handleChange">
                                <el-collapse-item title="Circulatory System" name="1">
                                    <div id="system-list">
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 1</p>
                                                    <p>CellNumber : 9652</p>
                                                    <p>Celltype :
                                                        B,Endothelial,Epithelial,Fibroblast,Macrophage,Mast,Muscle,Myofibroblast,T
                                                    </p>
                                                </div>
                                                <router-link slot="reference" to="info_tissue/Artery">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Artery.png" alt="">
                                                        <p style="width:58%">Artery</p>
                                                    </div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 21</p>
                                                    <p>CellNumber : 483286</p>
                                                    <p>Celltype :
                                                        B,CD4T,CD8T,DC,Mast,Megakaryocyte,Mono/Macro,Myofibroblast,Neutrophil,NK,pDC,Plasma,Treg
                                                    </p>
                                                </div>
                                                <router-link slot="reference" to="info_tissue/Blood">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Blood.png" alt="">Blood</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 12</p>
                                                    <p>CellNumber : 152656</p>
                                                    <p>Celltype : B,B/NK
                                                        Progenitor,Basophil,CD4T,CD8T,CMP,DC,Erythrocyte,Erythrocyte
                                                        Progenitor,GMP,HSC,HSPC,Lymphoid
                                                        Progenitor,Mast,Megakaryocyte,MEP,MLP,Mono/Macro,MPP,Multiple
                                                        Progenitor,Neutrophil,NK,Plasma,Pre B,T,T Cell Progenitor</p>

                                                </div>

                                                <router-link slot="reference" to="info_tissue/Bone-Marrow">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Bone-Marrow.png" alt="">Bone Marrow
                                                    </div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 3</p>
                                                    <p>CellNumber: 10504</p>
                                                    <p>Celltype:
                                                        CD8T,DC,Endothelial,Fibroblast,Mast,Mono/Macro,Muscle,Neutrophil,NK,Smooth
                                                        Muscle</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Heart">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Heart.png" alt="">Heart</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 1</p>
                                                    <p>CellNumber: 7770</p>
                                                    <p>Celltype: B,CD4T,CD8T,Mono/Macro,Treg</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Lymph-Node">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Lymph-Node.png" alt="">Lymph Node</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 2</p>
                                                    <p>CellNumber: 19957</p>
                                                    <p>Celltype: B,CD4T,CD8T,Endothelial,Erythrocyte,Lymphoid
                                                        Progenitor,Mono/Macro,Neutrophil,NK,Plasma,Treg</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Spleen">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Spleen.png" alt="">Spleen</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                    </div>
                                </el-collapse-item>
                                <el-collapse-item title="Digestive System" name="2">
                                    <div id="system-list">
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 1</p>
                                                    <p>CellNumber : 11024</p>
                                                    <p>Celltype :
                                                        B,CD4T,CD8T,Endothelial,Fibroblast,Macrophage,Melanocyte,Pericyte,Photoreceptor/RPE,Red
                                                        Blood Cell(RBC),Schwann,Smooth Muscle</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Common-Bile-Duct">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Common-Bile-Duct.png" alt="">Common
                                                        Bile Duct</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 3</p>
                                                    <p>CellNumber: 19821</p>
                                                    <p>Celltype: B,Basal,Columnar
                                                        Epithelial,Endothelial,Epithelial,Fibroblast,Goblet,Highly
                                                        Proliferative
                                                        Squamous Epithelial,Keratinocyte,Lymphatic
                                                        Endothelial,Mast,Mono/Macro,Muscle,Neutrophil,Smooth
                                                        Muscle,Squamous Epithelial
                                                    </p>
                                                </div>
                                                <router-link slot="reference" to="info_tissue/Esophagus">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Esophagus.png" alt="">Esophagus</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 2</p>
                                                    <p>CellNumber : 13936</p>
                                                    <p>Celltype:
                                                        B,CD4T,CD8T,Cholangiocyte,DC,Endothelial,Epithelial,Fibroblast,Mast,Mono/Macro,Muscle,Neutrophil
                                                    </p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Gall-Bladder">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Gall-Bladder.png" alt="">Gall Bladder
                                                    </div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 7</p>
                                                    <p>CellNumber: 59993</p>
                                                    <p>Celltype: B,CD4T,CD8T,Central Venous
                                                        LSEC,Cholangiocyte,DC,Endothelial,Epithelial,Erythrocyte,Hepatic
                                                        Oval,Hepatocyte,Kupffer,Mast,Mesenchymal,Mono/Macro,Muscle,Neutrophil,NK,Periportal
                                                        LSEC,Plasma,Portal Endothelial,Smooth Muscle,Treg</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Liver">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Liver.png" alt="">Liver</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 17</p>
                                                    <p>CellNumber : 137194</p>
                                                    <p>Celltype :
                                                        Absorptive,B,CD4T,CD8T,DC,Endothelial,Enterocyte,Enterocyte
                                                        Progenitor,Enteroendocrine,Epithelial,Fibroblast,Glial,Goblet,Goblet
                                                        Progenitor,ILC,Intraepithelial
                                                        Lymphocyte,M,Mast,Mesenchymal,Mono/Macro,Mucous
                                                        Neck,Muscle,Myofibroblast,Neuron,NK,Paneth,Paneth-like,Pericyte,Plasma,Schwann,Smooth
                                                        Muscle,Stem,Transit Amplifying,Treg,Tuft</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Large-Intestine">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Large-Intestine.png" alt="">Large
                                                        Intestine</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 4</p>
                                                    <p>CellNumber: 17808</p>
                                                    <p>Celltype:
                                                        B,CD4T,CD8T,Chief,Chromaffin,Endothelial,Epithelial,Fibroblast,FibSmo,Gastric
                                                        Pit,Granulocyte,Mast,Mono/Macro,Mucous,NK,Parietal,Peptic,Pit
                                                        Mucosal
                                                        Epithelial,Plasma,Smooth Muscle</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Stomach">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Stomach.png" alt="">Stomach</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 17</p>
                                                    <p>CellNumber : 137194</p>
                                                    <p>Celltype :
                                                        Absorptive,B,CD4T,CD8T,DC,Endothelial,Enterocyte,Enterocyte
                                                        Progenitor,Enteroendocrine,Epithelial,Fibroblast,Glial,Goblet,Goblet
                                                        Progenitor,ILC,Intraepithelial
                                                        Lymphocyte,M,Mast,Mesenchymal,Mono/Macro,Mucous
                                                        Neck,Muscle,Myofibroblast,Neuron,NK,Paneth,Paneth-like,Pericyte,Plasma,Schwann,Smooth
                                                        Muscle,Stem,Transit Amplifying,Treg,Tuft</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Small-Intestine">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Small-Intestine.png" alt="">Small
                                                        Intestine</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 1</p>
                                                    <p>CellNumber : 17198</p>
                                                    <p>Celltype :
                                                        B,CD4T,CD8T,Endothelial,Fibroblast,Mesothelial,Mononuclear
                                                        Phagocyte,Myofibroblast</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Peritoneum">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Peritoneum.png" alt="">Peritoneum</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                    </div>
                                </el-collapse-item>
                                <el-collapse-item title="Endocrine System" name="3">
                                    <div id="system-list">
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 2</p>
                                                    <p>CellNumber: 16187</p>
                                                    <p>Celltype:
                                                        B,CD4T,CD8T,Chromoblast,Endothelial,Fibroblast,Macrophage,Mono/Macro,Muscle,Neuron,Neutrophil,NK,Stromal-like,Zona
                                                        Fasciculata</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Adrenal-Gland">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Adrenal-Gland.png" alt="">
                                                        <p style="width:58%">Adrenal Gland</p>
                                                    </div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 4</p>
                                                    <p>CellNumber: 80562</p>
                                                    <p>Celltype: B,Basal,CD8T,Fibroblast,Luminal,Luminal
                                                        Progenitor,Lymphatic
                                                        Endothelial,Mature Luminal,Mono/Macro,NK,Pericyte,Vascular
                                                        Endothelial</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Breast">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Breast.png" alt="">Breast</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 4</p>
                                                    <p>CellNumber: 21812</p>
                                                    <p>Celltype:
                                                        Acinar,Alpha,APC,Beta,Delta,Duct,Endocrine,Endothelial,Epsilon,Exocrine,Fibroblast,Mast,Mesenchymal,Mono/Macro,Muscle,Pancreatic
                                                        Polypeptide,Pancreatic Stellate,Schwann</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Pancreas">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Pancreas.png" alt="">Pancreas</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 5</p>
                                                    <p>CellNumber: 64984</p>
                                                    <p>Celltype:
                                                        APC,CD4T,Cytotrophoblast,DC,Decidual,Endothelial,Epithelial,Epithelial
                                                        Glandular,Erythrocyte,Fibroblast,Granulocyte,Hofbauer,Mono/Macro,Muscle,Neutrophil,Proliferating,Syncytiotrophoblast,Trophoblast,Vascular
                                                        Smooth Muscle</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Placenta">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Placenta.png" alt="">Placenta</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 1</p>
                                                    <p>CellNumber: 59857</p>
                                                    <p>Celltype: Apical/Narrow,Basal,Clear,Efferent
                                                        Duct,Endothelial,Germ,Leydig,Mono/Macro,Peritubular
                                                        Myoid,Principal,Sertoli,Sperm,Spermatid,Spermatocyte,Spermatogonia,Spermatogonial
                                                        Stem,Stromal,Vascular Smooth Muscle</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Thymus">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Thymus.png" alt="">Thymus</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                    </div>
                                </el-collapse-item>
                                <el-collapse-item title="Nervous system" name="4">
                                    <div id="system-list">
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 8</p>
                                                    <p>CellNumber: 79895</p>
                                                    <p>Celltype: Astrocyte,B,CD4T,CD8T,Dopaminergic
                                                        Neuron,Endothelial,Ependymal,Epithelial,Excitatory
                                                        Neuron,GABAergic
                                                        Neuron,Granule,Inhibitory
                                                        Neuron,Interneuron,Microglia,Mono/Macro,Muscle,Neutrophil,NK,Oligodendrocyte,OPC,Pericyte,Plasma,Purkinje
                                                        Neuron,Treg</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Cerebellum">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Cerebellum.png" alt="">Cerebellum</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 8</p>
                                                    <p>CellNumber: 79895</p>
                                                    <p>Celltype: Astrocyte,B,CD4T,CD8T,Dopaminergic
                                                        Neuron,Endothelial,Ependymal,Epithelial,Excitatory
                                                        Neuron,GABAergic
                                                        Neuron,Granule,Inhibitory
                                                        Neuron,Interneuron,Microglia,Mono/Macro,Muscle,Neutrophil,NK,Oligodendrocyte,OPC,Pericyte,Plasma,Purkinje
                                                        Neuron,Treg</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Cerebral-Cortex">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Cerebral-Cortex.png" alt="">Cerebral
                                                        Cortex</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 8</p>
                                                    <p>CellNumber: 79895</p>
                                                    <p>Celltype: Astrocyte,B,CD4T,CD8T,Dopaminergic
                                                        Neuron,Endothelial,Ependymal,Epithelial,Excitatory
                                                        Neuron,GABAergic
                                                        Neuron,Granule,Inhibitory
                                                        Neuron,Interneuron,Microglia,Mono/Macro,Muscle,Neutrophil,NK,Oligodendrocyte,OPC,Pericyte,Plasma,Purkinje
                                                        Neuron,Treg</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Cerebrospinal-Fluid">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Cerebrospinal-Fluid.png"
                                                            alt="">Cerebrospinal Fluid</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 8</p>
                                                    <p>CellNumber: 79895</p>
                                                    <p>Celltype: Astrocyte,B,CD4T,CD8T,Dopaminergic
                                                        Neuron,Endothelial,Ependymal,Epithelial,Excitatory
                                                        Neuron,GABAergic
                                                        Neuron,Granule,Inhibitory
                                                        Neuron,Interneuron,Microglia,Mono/Macro,Muscle,Neutrophil,NK,Oligodendrocyte,OPC,Pericyte,Plasma,Purkinje
                                                        Neuron,Treg</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Midbrain">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Midbrain.png" alt="">Midbrain</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                    </div>
                                </el-collapse-item>
                                <el-collapse-item title="Reproductive System" name="5">
                                    <div id="system-list">
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 2</p>
                                                    <p>CellNumber: 27674</p>
                                                    <p>Celltype: B,CD4T,CD8T,Endothelial,Epithelial,Fibroblast,Lymphatic
                                                        Endothelial,Mast,Mono/Macro,NK,Smooth Muscle,Vascular
                                                        Endothelial,Vascular
                                                        Smooth Muscle</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Cervix">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Cervix.png" alt="">Cervix</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 4</p>
                                                    <p>CellNumber: 54801</p>
                                                    <p>Celltype: B,CD4T,CD8T,DC,Endothelial,Extravillous
                                                        Trophoblast,Fibroblast,Glandular
                                                        Epithelial,Granulocyte,Hofbauer,ILC,Mast,Mono/Macro,NK,Pericyte,Perivascular,Plasma,Syncytiotrophoblast,Treg,Villous
                                                        Cytotrophoblast</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Decidua">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Decidua.png" alt="">Decidua</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 1</p>
                                                    <p>CellNumber: 4500</p>
                                                    <p>Celltype:
                                                        B,CD4T,CD8T,Ciliated,DC,Endothelial,Epithelial,Fibroblast,Mast,Mono/Macro,Muscle,Neutrophil
                                                    </p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Fallopian">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Fallopian.png" alt="">Fallopian</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 3</p>
                                                    <p>CellNumber: 52641</p>
                                                    <p>Celltype:
                                                        B,CD4T,CD8T,Endothelial,Erythrocyte,Fibroblast,Germ,Granulosa,Mono/Macro,NK,Smooth
                                                        Muscle,Theca</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Ovary">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Ovary.png" alt="">Ovary</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 4</p>
                                                    <p>CellNumber : 59857</p>
                                                    <p>Celltype : Apical/Narrow,Basal,Clear,Efferent
                                                        Duct,Endothelial,Germ,Leydig,Macrophage,Peritubular Myoid
                                                        Cell(PTM),Principal,Sertoli,Sperm,Spermatid,Spermatocyte,Spermatogonia,Spermatogonial
                                                        Stem cell(SSC),Stromal,Vascular Smooth Muscle</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Testis">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Testis.png" alt="">Testis</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 1</p>
                                                    <p>CellNumber: 77478</p>
                                                    <p>Celltype:
                                                        CD8T,Endometrial,Endothelial,Epithelial,Fibroblast,Mono/Macro,Muscle,NK,Smooth
                                                        Muscle</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Uterus">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Uterus.png" alt="">Uterus</div>
                                                </router-link>
                                            </el-popover>
                                        </div>

                                    </div>
                                </el-collapse-item>
                                <el-collapse-item title="Respiratory System" name="6">
                                    <div id="system-list">
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 3</p>
                                                    <p>CellNumber: 21167</p>
                                                    <p>Celltype: B,Basal,Basal
                                                        Epithelial,Brush/PNEC,CD4T,CD8T,Chondrocyte,Ciliated,DC,Endothelial,Fibroblast,Goblet,Mono/Macro,Muscle,NK,Plasma,Secretory,Smooth
                                                        Muscle</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Airway">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Airway.png" alt="">
                                                        <p style="width:58%">Airway</p>
                                                    </div>
                                                </router-link>
                                            </el-popover>

                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 12</p>
                                                    <p>CellNumber: 108428</p>
                                                    <p>Celltype: Alveolar Bipotent Progenitor,Alveolar Type 1,Alveolar
                                                        Type
                                                        2,B,Basal,CD4T,CD8T,Chondrocyte,Ciliated,Club,DC,Endothelial,Fibroblast,Goblet,Intermediate(Club-Basal),Ionocyte,Lymphatic
                                                        Endothelial,Mast,Megakaryocyte,Mono/Macro,Mucous,Muscle,Myofibroblast,Neuroendocrine,Neutrophil,NK,Secretory
                                                        Epithelial,Smooth Muscle</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Lung">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Lung.png" alt="">Lung</div>
                                                </router-link>
                                            </el-popover>
                                        </div>

                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 1</p>
                                                    <p>CellNumber: 9794</p>
                                                    <p>Celltype: Fibroblast,Mesothelial,Mono/Macro,Neutrophil,NK</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Pleura">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Pleura.png" alt="">Pleura</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                    </div>
                                </el-collapse-item>
                                <el-collapse-item title="Sensory nervous System" name="7">
                                    <div id="system-list">
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 1</p>
                                                    <p>CellNumber : 9839</p>
                                                    <p>Celltype :
                                                        B,CD4T,CD8T,Endothelial,Fibroblast,Macrophage,Melanocyte,Pericyte,Photoreceptor/RPE,Red
                                                        Blood Cell(RBC),Schwann,Smooth Muscle</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Choroid">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Eye.png" alt="">Choroid</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 1</p>
                                                    <p>CellNumber: 30507</p>
                                                    <p>Celltype: Chondral
                                                        Stem,Chondrocyte,Endothelial,Mast,PVC,SC,Skeletal Stem</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Ear">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Ear.png" alt="">Ear</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 7</p>
                                                    <p>CellNumber: 184158</p>
                                                    <p>Celltype: Amacirne,Amacrine,Astrocyte,B,Bipolar,Blood
                                                        Vesel,CD4T,CD8T,Cone,Cone
                                                        Off BC,Cone On BC/Rod BC,Conjunctival Basal Epithelium,Corneal
                                                        Basal
                                                        Epithelium,Corneal Endothelium,Corneal
                                                        Stromal,Endothelial,Erythrocyte,Fibroblast,Fibroblastic Corneal
                                                        Endothelial,Ganglion,Horizontal,Limbal Epithelial,Limbal
                                                        Fibroblast,Limbal
                                                        Neural Crest Progenitor,Limbal Progenitor,Limbal Stroma
                                                        Keratocyte,Limbal
                                                        Superficial Epithelium,Limbal Suprabasal,Lymphatic
                                                        Endothelial,Lymphatic
                                                        Vessel,Mast,Melanocyte,Microglia,Mono/Macro,Muller,NK,Pericyte,Photoreceptor,Retinal
                                                        Ganglion,Rod,RPE,Schwann,Smooth Muscle,Stromal,Vascular
                                                        Endothelial</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Eye">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Eye.png" alt="">Eye</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 2</p>
                                                    <p>CellNumber: 46896</p>
                                                    <p>Celltype: B,Basal,Bowman’s
                                                        Gland,CD4T,CD8T,Club,DC,Fibroblast,Globose
                                                        Basal,Goblet,Macrophage,Mast,Monocyte,Multiciliated,Neuron,NK,OEC,Olfactory
                                                        Horizontal Basal,Olfactory
                                                        Microvillar,Pericyte,Plasma,Respiratory
                                                        Ciliated,Respiratory Horizontal Basal,Smooth
                                                        Muscle,Suprabasal,Sustentacular</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Nose">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Nose.png" alt="">Nose</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 2</p>
                                                    <p>CellNumber: 12259</p>
                                                    <p>Celltype:
                                                        B,CD4T,CD8T,DC,Endothelial,Fibroblast,Mono/Macro,Muscle,Neutrophil,Plasma,Thyrocyte
                                                    </p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Thyroid">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Thyroid.png" alt="">Thyroid</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 10</p>
                                                    <p>CellNumber: 118799</p>
                                                    <p>Celltype: Basal,Bulge,CD4T,CD8T,Chondrocyte,Corneum,DC,Eccrine
                                                        Duct,Eccrine
                                                        Gland,Endothelial,Epidermal Stem,Epidermis,Fibroblast,IFE
                                                        Basal,IFE Granular,IFE
                                                        Mitotic,IFE
                                                        Spinous,Infundibulum,IRS/Cuticle,Isthmus,Keratinocyte,Langerhans,Lymph
                                                        Endothelial,Mast,Matrix/Cortex/Medulla,Melanocyte,Mesenchymal,Mono/Macro,Myofibroblast,Neural,NK,ORS
                                                        B,Pericyte,Schwann,Sebaceous/Apocrine,Secretory
                                                        Epithelial,Smooth
                                                        Muscle,Spinous,Sweat Gland,Treg,Vasc Endothelial</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Skin">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Skin.png" alt="">Skin</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">

                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 9</p>
                                                    <p>CellNumber: 159167</p>
                                                    <p>Celltype: Adipocyte,Adipocyte
                                                        Progenitor,APC,B,CD4T,CD8T,Chondrocyte,Classic
                                                        Endothelial,DC,Endothelial,Fibroblast,Granulocyte,Intermediate,Lymphatic
                                                        Endothelial,Mast,Mesothelial,Microvascular
                                                        Endothelial,Mono/Macro,Neutrophil,NK,Pericyte,Preadipocyte,Smooth
                                                        Muscle,Stem
                                                    </p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Adipose">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Adipose.png" alt="">
                                                        <p style="width:58%">Adipose</p>
                                                    </div>
                                                </router-link>
                                            </el-popover>
                                        </div>

                                    </div>
                                </el-collapse-item>
                                <el-collapse-item title="Urinary System" name="8">
                                    <div id="system-list">
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 4</p>
                                                    <p>CellNumber : 17072</p>
                                                    <p>Celltype:
                                                        B,Basal,CD4T,CD8T,Endothelial,Fibroblast,Intermediate(Umbrella-Basal),Interstitial,Macrophage,Mast,Monocyte,Neutrophil,NK,Smooth
                                                        Muscle,T_Unknown,TNNT1+ Epithelial,Umbrella,Urothelial</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Bladder">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Bladder.png" alt="">Bladder</div>
                                                </router-link>
                                            </el-popover>

                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 9</p>
                                                    <p>CellNumber: 83462</p>
                                                    <p>Celltype: CNT,Collecting Duct,Connecting Tubule,Distal
                                                        Tubule,Epithelial,Epithelial||renal Pelvis,Glomerular,Glomerular
                                                        Parietal
                                                        Epithelial,Glomerular Visceral Epithelial,Intercalated,Loop Of
                                                        Henle,Parietal
                                                        Epithelial,Podocyte,Principal,Proximal Convoluted Tubule
                                                        ,Proximal Straight
                                                        Tubule,Proximal Tubule,Renal Alpha-intercalated,Renal
                                                        Beta-intercalated,Stem,Thick Ascending Limb</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Kidney">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Kidney.png" alt="">Kidney</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 4</p>
                                                    <p>CellNumber: 193376</p>
                                                    <p>Celltype:
                                                        Basal,CD4T,Club,Endothelial,Epithelial,Fibroblast,Hillock,Leukocyte,Luminal,Mono/Macro,Muscle,Neuroendocrine,Neutrophil,NK,Smooth
                                                        Muscle,Stress</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Prostate">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Prostate.png" alt="">Prostate</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">

                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 1</p>
                                                    <p>CellNumber : 1886</p>
                                                    <p>Celltype: B,Basal,Fibroblast,Mono/Macro,Muscle,NK,Superficial</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Ureter">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Prostate.png" alt="">Ureter</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                    </div>
                                </el-collapse-item>
                                <el-collapse-item title="Skeletal System" name="9">
                                    <div id="system-list">
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 1</p>
                                                    <p>CellNumber : 18625</p>
                                                    <p>Celltype : B,B/NK Progenitor,Basophil,CD4T,CD8T,Common myeloid
                                                        progenitor(CMP),Dendritic
                                                        cell(DC),EPC,Erythroblast,Erythroid,Granulocyte_monocyte
                                                        progenitor(GMP),Hematopoietic stem cell(HSC),Hemopoietic
                                                        stem/progenitor
                                                        cell(HSPC),Lymphoid Progenitor,Lymphoid-primed multipotent
                                                        progenitor(LMPP),MPP,Macrophage,Megakaryocyte-erythroid
                                                        progenitor(MEP),Monocyte,Multi-lymphoid progenitor(MLP),Natural
                                                        killer
                                                        cell(NK),Neutrophil,T Progenitor</p>

                                                </div>

                                                <router-link slot="reference" to="info_tissue/Bone">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Bone.png" alt="">Bone</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                        <div id="tissue_one">
                                            <el-popover placement="right" width="400" trigger="hover">
                                                <div>
                                                    <p>Dataset : 4</p>
                                                    <p>CellNumber: 33339</p>
                                                    <p>Celltype:
                                                        Adipocyte,B,CD4T,CD8T,DC,Endothelial,Fibroblast,Macrophage,Mast,Muscle
                                                        Progenitor,Myeloid,Neutrophil,NK,PiTendon
                                                        Progenitor,Satellite,Smooth
                                                        Muscle,T,T_Unknown,Tendon Progenitor,Tendon Progenitor Stem</p>

                                                </div>
                                                <router-link slot="reference" to="info_tissue/Muscle">
                                                    <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                            src="@/assets/tissue/Muscle.png" alt="">Muscle</div>
                                                </router-link>
                                            </el-popover>
                                        </div>
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                        </div>
                        <div id="old" v-if="radio==3">
                            <div id="tissue_one">
                                <el-popover placement="right" width="400" trigger="hover">
                                    <div>
                                        <p>Dataset : 9</p>
                                        <p>CellNumber: 159167</p>
                                        <p>Celltype: Adipocyte,Adipocyte Progenitor,APC,B,CD4T,CD8T,Chondrocyte,Classic
                                            Endothelial,DC,Endothelial,Fibroblast,Granulocyte,Intermediate,Lymphatic
                                            Endothelial,Mast,Mesothelial,Microvascular
                                            Endothelial,Mono/Macro,Neutrophil,NK,Pericyte,Preadipocyte,Smooth
                                            Muscle,Stem
                                        </p>

                                    </div>
                                    <router-link slot="reference" to="info_tissue/Adipose">
                                        <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                src="@/assets/tissue/Adipose.png" alt="">
                                            <p style="width:58%">Adipose</p>
                                        </div>
                                    </router-link>
                                </el-popover>
                            </div>

                            <div id="tissue_one">
                                <el-popover placement="right" width="400" trigger="hover">
                                    <div>
                                        <p>Dataset : 2</p>
                                        <p>CellNumber: 16187</p>
                                        <p>Celltype:
                                            B,CD4T,CD8T,Chromoblast,Endothelial,Fibroblast,Macrophage,Mono/Macro,Muscle,Neuron,Neutrophil,NK,Stromal-like,Zona
                                            Fasciculata</p>

                                    </div>
                                    <router-link slot="reference" to="info_tissue/Adrenal-Gland">
                                        <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                src="@/assets/tissue/Adrenal-Gland.png" alt="">
                                            <p style="width:58%">Adrenal Gland</p>
                                        </div>
                                    </router-link>
                                </el-popover>

                            </div>

                            <div id="tissue_one">
                                <el-popover placement="right" width="400" trigger="hover">
                                    <div>
                                        <p>Dataset : 3</p>
                                        <p>CellNumber: 21167</p>
                                        <p>Celltype: B,Basal,Basal
                                            Epithelial,Brush/PNEC,CD4T,CD8T,Chondrocyte,Ciliated,DC,Endothelial,Fibroblast,Goblet,Mono/Macro,Muscle,NK,Plasma,Secretory,Smooth
                                            Muscle</p>

                                    </div>
                                    <router-link slot="reference" to="info_tissue/Airway">
                                        <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                src="@/assets/tissue/Airway.png" alt="">
                                            <p style="width:58%">Airway</p>
                                        </div>
                                    </router-link>
                                </el-popover>

                            </div>
                            <div id="tissue_one">


                                <el-popover placement="right" width="400" trigger="hover">
                                    <div>
                                        <p>Dataset : 1</p>
                                        <p>CellNumber : 9652</p>
                                        <p>Celltype :
                                            B,Endothelial,Epithelial,Fibroblast,Macrophage,Mast,Muscle,Myofibroblast,T
                                        </p>

                                    </div>
                                    <router-link slot="reference" to="info_tissue/Artery">
                                        <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                src="@/assets/tissue/Artery.png" alt="">
                                            <p style="width:58%">Artery</p>
                                        </div>
                                    </router-link>
                                </el-popover>

                            </div>





                            <br>

                            <div id="tissue_one">
                                <el-popover placement="right" width="400" trigger="hover">
                                    <div>
                                        <p>Dataset : 4</p>
                                        <p>CellNumber : 17072</p>
                                        <p>Celltype:
                                            B,Basal,CD4T,CD8T,Endothelial,Fibroblast,Intermediate(Umbrella-Basal),Interstitial,Macrophage,Mast,Monocyte,Neutrophil,NK,Smooth
                                            Muscle,T_Unknown,TNNT1+ Epithelial,Umbrella,Urothelial</p>

                                    </div>
                                    <router-link slot="reference" to="info_tissue/Bladder">
                                        <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                src="@/assets/tissue/Bladder.png" alt="">Bladder</div>
                                    </router-link>
                                </el-popover>

                            </div>
                            <div id="tissue_one">
                                <el-popover placement="right" width="400" trigger="hover">
                                    <div>
                                        <p>Dataset : 21</p>
                                        <p>CellNumber : 483286</p>
                                        <p>Celltype :
                                            B,CD4T,CD8T,DC,Mast,Megakaryocyte,Mono/Macro,Myofibroblast,Neutrophil,NK,pDC,Plasma,Treg
                                        </p>

                                    </div>
                                    <router-link slot="reference" to="info_tissue/Blood">
                                        <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                src="@/assets/tissue/Blood.png" alt="">Blood</div>
                                    </router-link>
                                </el-popover>

                            </div>
                            <div id="tissue_one">
                                <el-popover placement="right" width="400" trigger="hover">
                                    <div>
                                        <p>Dataset : 1</p>
                                        <p>CellNumber : 18625</p>
                                        <p>Celltype : B,B/NK Progenitor,Basophil,CD4T,CD8T,Common myeloid
                                            progenitor(CMP),Dendritic
                                            cell(DC),EPC,Erythroblast,Erythroid,Granulocyte_monocyte
                                            progenitor(GMP),Hematopoietic stem cell(HSC),Hemopoietic stem/progenitor
                                            cell(HSPC),Lymphoid Progenitor,Lymphoid-primed multipotent
                                            progenitor(LMPP),MPP,Macrophage,Megakaryocyte-erythroid
                                            progenitor(MEP),Monocyte,Multi-lymphoid progenitor(MLP),Natural killer
                                            cell(NK),Neutrophil,T Progenitor</p>

                                    </div>

                                    <router-link slot="reference" to="info_tissue/Bone">
                                        <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                src="@/assets/tissue/Bone.png" alt="">Bone</div>
                                    </router-link>
                                </el-popover>
                            </div>
                            <div id="tissue_one">
                                <el-popover placement="right" width="400" trigger="hover">
                                    <div>
                                        <p>Dataset : 12</p>
                                        <p>CellNumber : 152656</p>
                                        <p>Celltype : B,B/NK
                                            Progenitor,Basophil,CD4T,CD8T,CMP,DC,Erythrocyte,Erythrocyte
                                            Progenitor,GMP,HSC,HSPC,Lymphoid
                                            Progenitor,Mast,Megakaryocyte,MEP,MLP,Mono/Macro,MPP,Multiple
                                            Progenitor,Neutrophil,NK,Plasma,Pre B,T,T Cell Progenitor</p>

                                    </div>

                                    <router-link slot="reference" to="info_tissue/Bone-Marrow">
                                        <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                src="@/assets/tissue/Bone-Marrow.png" alt="">Bone Marrow</div>
                                    </router-link>
                                </el-popover>
                            </div>
                            <br>
                            <div id="tissue_one">
                                <el-popover placement="right" width="400" trigger="hover">
                                    <div>
                                        <p>Dataset : 4</p>
                                        <p>CellNumber: 80562</p>
                                        <p>Celltype: B,Basal,CD8T,Fibroblast,Luminal,Luminal Progenitor,Lymphatic
                                            Endothelial,Mature Luminal,Mono/Macro,NK,Pericyte,Vascular Endothelial</p>

                                    </div>
                                    <router-link slot="reference" to="info_tissue/Breast">
                                        <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                src="@/assets/tissue/Breast.png" alt="">Breast</div>
                                    </router-link>
                                </el-popover>
                            </div>
                            <div id="tissue_one">
                                <el-popover placement="right" width="400" trigger="hover">
                                    <div>
                                        <p>Dataset : 8</p>
                                        <p>CellNumber: 79895</p>
                                        <p>Celltype: Astrocyte,B,CD4T,CD8T,Dopaminergic
                                            Neuron,Endothelial,Ependymal,Epithelial,Excitatory
                                            Neuron,GABAergic
                                            Neuron,Granule,Inhibitory
                                            Neuron,Interneuron,Microglia,Mono/Macro,Muscle,Neutrophil,NK,Oligodendrocyte,OPC,Pericyte,Plasma,Purkinje
                                            Neuron,Treg</p>

                                    </div>
                                    <router-link slot="reference" to="info_tissue/Cerebellum">
                                        <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                src="@/assets/tissue/Cerebellum.png" alt="">Cerebellum</div>
                                    </router-link>
                                </el-popover>

                            </div>
                            <div id="tissue_one">
                                <el-popover placement="right" width="400" trigger="hover">
                                    <div>
                                        <p>Dataset : 8</p>
                                        <p>CellNumber: 79895</p>
                                        <p>Celltype: Astrocyte,B,CD4T,CD8T,Dopaminergic
                                            Neuron,Endothelial,Ependymal,Epithelial,Excitatory
                                            Neuron,GABAergic
                                            Neuron,Granule,Inhibitory
                                            Neuron,Interneuron,Microglia,Mono/Macro,Muscle,Neutrophil,NK,Oligodendrocyte,OPC,Pericyte,Plasma,Purkinje
                                            Neuron,Treg</p>

                                    </div>
                                    <router-link slot="reference" to="info_tissue/Cerebral-Cortex">
                                        <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                src="@/assets/tissue/Cerebral-Cortex.png" alt="">Cerebral Cortex</div>
                                    </router-link>
                                </el-popover>

                            </div>
                            <div id="tissue_one">
                                <el-popover placement="right" width="400" trigger="hover">
                                    <div>
                                        <p>Dataset : 8</p>
                                        <p>CellNumber: 79895</p>
                                        <p>Celltype: Astrocyte,B,CD4T,CD8T,Dopaminergic
                                            Neuron,Endothelial,Ependymal,Epithelial,Excitatory
                                            Neuron,GABAergic
                                            Neuron,Granule,Inhibitory
                                            Neuron,Interneuron,Microglia,Mono/Macro,Muscle,Neutrophil,NK,Oligodendrocyte,OPC,Pericyte,Plasma,Purkinje
                                            Neuron,Treg</p>

                                    </div>
                                    <router-link slot="reference" to="info_tissue/Cerebrospinal-Fluid">
                                        <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                src="@/assets/tissue/Cerebrospinal-Fluid.png" alt="">Cerebrospinal Fluid
                                        </div>
                                    </router-link>
                                </el-popover>

                            </div>
                            <div id="tissue_one">
                                <el-popover placement="right" width="400" trigger="hover">
                                    <div>
                                        <p>Dataset : 2</p>
                                        <p>CellNumber: 27674</p>
                                        <p>Celltype: B,CD4T,CD8T,Endothelial,Epithelial,Fibroblast,Lymphatic
                                            Endothelial,Mast,Mono/Macro,NK,Smooth Muscle,Vascular Endothelial,Vascular
                                            Smooth Muscle</p>

                                    </div>
                                    <router-link slot="reference" to="info_tissue/Cervix">
                                        <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                src="@/assets/tissue/Cervix.png" alt="">Cervix</div>
                                    </router-link>
                                </el-popover>
                            </div>
                            <div id="tissue_one">
                                <el-popover placement="right" width="400" trigger="hover">
                                    <div>
                                        <p>Dataset : 1</p>
                                        <p>CellNumber : 9839</p>
                                        <p>Celltype :
                                            B,CD4T,CD8T,Endothelial,Fibroblast,Macrophage,Melanocyte,Pericyte,Photoreceptor/RPE,Red
                                            Blood Cell(RBC),Schwann,Smooth Muscle</p>

                                    </div>
                                    <router-link slot="reference" to="info_tissue/Choroid">
                                        <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                src="@/assets/tissue/Eye.png" alt="">Choroid</div>
                                    </router-link>
                                </el-popover>

                            </div>
                            <div id="tissue_one">
                                <el-popover placement="right" width="400" trigger="hover">
                                    <div>
                                        <p>Dataset : 1</p>
                                        <p>CellNumber : 11024</p>
                                        <p>Celltype :
                                            B,CD4T,CD8T,Endothelial,Fibroblast,Macrophage,Melanocyte,Pericyte,Photoreceptor/RPE,Red
                                            Blood Cell(RBC),Schwann,Smooth Muscle</p>

                                    </div>
                                    <router-link slot="reference" to="info_tissue/Common-Bile-Duct">
                                        <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                src="@/assets/tissue/Common-Bile-Duct.png" alt="">Common Bile Duct</div>
                                    </router-link>
                                </el-popover>
                            </div>


                            <div id="tissue_one">
                                <el-popover placement="right" width="400" trigger="hover">
                                    <div>
                                        <p>Dataset : 4</p>
                                        <p>CellNumber: 54801</p>
                                        <p>Celltype: B,CD4T,CD8T,DC,Endothelial,Extravillous
                                            Trophoblast,Fibroblast,Glandular
                                            Epithelial,Granulocyte,Hofbauer,ILC,Mast,Mono/Macro,NK,Pericyte,Perivascular,Plasma,Syncytiotrophoblast,Treg,Villous
                                            Cytotrophoblast</p>

                                    </div>
                                    <router-link slot="reference" to="info_tissue/Decidua">
                                        <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                src="@/assets/tissue/Decidua.png" alt="">Decidua</div>
                                    </router-link>
                                </el-popover>
                            </div>
                            <div id="tissue_one">
                                <el-popover placement="right" width="400" trigger="hover">
                                    <div>
                                        <p>Dataset : 1</p>
                                        <p>CellNumber: 30507</p>
                                        <p>Celltype: Chondral Stem,Chondrocyte,Endothelial,Mast,PVC,SC,Skeletal Stem</p>

                                    </div>
                                    <router-link slot="reference" to="info_tissue/Ear">
                                        <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                src="@/assets/tissue/Ear.png" alt="">Ear</div>
                                    </router-link>
                                </el-popover>
                            </div>
                            <div id="tissue_one">
                                <el-popover placement="right" width="400" trigger="hover">
                                    <div>
                                        <p>Dataset : 3</p>
                                        <p>CellNumber: 19821</p>
                                        <p>Celltype: B,Basal,Columnar
                                            Epithelial,Endothelial,Epithelial,Fibroblast,Goblet,Highly Proliferative
                                            Squamous Epithelial,Keratinocyte,Lymphatic
                                            Endothelial,Mast,Mono/Macro,Muscle,Neutrophil,Smooth Muscle,Squamous
                                            Epithelial
                                        </p>

                                    </div>
                                    <router-link slot="reference" to="info_tissue/Esophagus">
                                        <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                src="@/assets/tissue/Esophagus.png" alt="">Esophagus</div>
                                    </router-link>
                                </el-popover>
                            </div>
                            <div id="tissue_one">
                                <el-popover placement="right" width="400" trigger="hover">
                                    <div>
                                        <p>Dataset : 7</p>
                                        <p>CellNumber: 184158</p>
                                        <p>Celltype: Amacirne,Amacrine,Astrocyte,B,Bipolar,Blood
                                            Vesel,CD4T,CD8T,Cone,Cone
                                            Off BC,Cone On BC/Rod BC,Conjunctival Basal Epithelium,Corneal Basal
                                            Epithelium,Corneal Endothelium,Corneal
                                            Stromal,Endothelial,Erythrocyte,Fibroblast,Fibroblastic Corneal
                                            Endothelial,Ganglion,Horizontal,Limbal Epithelial,Limbal Fibroblast,Limbal
                                            Neural Crest Progenitor,Limbal Progenitor,Limbal Stroma Keratocyte,Limbal
                                            Superficial Epithelium,Limbal Suprabasal,Lymphatic Endothelial,Lymphatic
                                            Vessel,Mast,Melanocyte,Microglia,Mono/Macro,Muller,NK,Pericyte,Photoreceptor,Retinal
                                            Ganglion,Rod,RPE,Schwann,Smooth Muscle,Stromal,Vascular Endothelial</p>

                                    </div>
                                    <router-link slot="reference" to="info_tissue/Eye">
                                        <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                src="@/assets/tissue/Eye.png" alt="">Eye</div>
                                    </router-link>
                                </el-popover>
                            </div>
                            <div id="tissue_one">
                                <el-popover placement="right" width="400" trigger="hover">
                                    <div>
                                        <p>Dataset : 1</p>
                                        <p>CellNumber: 4500</p>
                                        <p>Celltype:
                                            B,CD4T,CD8T,Ciliated,DC,Endothelial,Epithelial,Fibroblast,Mast,Mono/Macro,Muscle,Neutrophil
                                        </p>

                                    </div>
                                    <router-link slot="reference" to="info_tissue/Fallopian">
                                        <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                src="@/assets/tissue/Fallopian.png" alt="">Fallopian</div>
                                    </router-link>
                                </el-popover>
                            </div>
                            <div id="tissue_one">
                                <el-popover placement="right" width="400" trigger="hover">
                                    <div>
                                        <p>Dataset : 2</p>
                                        <p>CellNumber : 13936</p>
                                        <p>Celltype:
                                            B,CD4T,CD8T,Cholangiocyte,DC,Endothelial,Epithelial,Fibroblast,Mast,Mono/Macro,Muscle,Neutrophil
                                        </p>
                                    </div>
                                    <router-link slot="reference" to="info_tissue/Gall-Bladder">
                                        <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                src="@/assets/tissue/Gall-Bladder.png" alt="">Gall Bladder</div>
                                    </router-link>
                                </el-popover>
                            </div>
                            <div id="tissue_one">
                                <el-popover placement="right" width="400" trigger="hover">
                                    <div>
                                        <p>Dataset : 3</p>
                                        <p>CellNumber: 10504</p>
                                        <p>Celltype:
                                            CD8T,DC,Endothelial,Fibroblast,Mast,Mono/Macro,Muscle,Neutrophil,NK,Smooth
                                            Muscle</p>

                                    </div>
                                    <router-link slot="reference" to="info_tissue/Heart">
                                        <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                src="@/assets/tissue/Heart.png" alt="">Heart</div>
                                    </router-link>
                                </el-popover>
                            </div>

                            <div id="tissue_one">
                                <el-popover placement="right" width="400" trigger="hover">
                                    <div>
                                        <p>Dataset : 9</p>
                                        <p>CellNumber: 83462</p>
                                        <p>Celltype: CNT,Collecting Duct,Connecting Tubule,Distal
                                            Tubule,Epithelial,Epithelial||renal Pelvis,Glomerular,Glomerular Parietal
                                            Epithelial,Glomerular Visceral Epithelial,Intercalated,Loop Of
                                            Henle,Parietal
                                            Epithelial,Podocyte,Principal,Proximal Convoluted Tubule ,Proximal Straight
                                            Tubule,Proximal Tubule,Renal Alpha-intercalated,Renal
                                            Beta-intercalated,Stem,Thick Ascending Limb</p>

                                    </div>
                                    <router-link slot="reference" to="info_tissue/Kidney">
                                        <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                src="@/assets/tissue/Kidney.png" alt="">Kidney</div>
                                    </router-link>
                                </el-popover>
                            </div>
                            <div id="tissue_one">
                                <el-popover placement="right" width="400" trigger="hover">
                                    <div>
                                        <p>Dataset : 17</p>
                                        <p>CellNumber : 137194</p>
                                        <p>Celltype :
                                            Absorptive,B,CD4T,CD8T,DC,Endothelial,Enterocyte,Enterocyte
                                            Progenitor,Enteroendocrine,Epithelial,Fibroblast,Glial,Goblet,Goblet
                                            Progenitor,ILC,Intraepithelial
                                            Lymphocyte,M,Mast,Mesenchymal,Mono/Macro,Mucous
                                            Neck,Muscle,Myofibroblast,Neuron,NK,Paneth,Paneth-like,Pericyte,Plasma,Schwann,Smooth
                                            Muscle,Stem,Transit Amplifying,Treg,Tuft</p>

                                    </div>
                                    <router-link slot="reference" to="info_tissue/Large-Intestine">
                                        <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                src="@/assets/tissue/Large-Intestine.png" alt="">Large Intestine</div>
                                    </router-link>
                                </el-popover>
                            </div>
                            <div id="tissue_one">
                                <el-popover placement="right" width="400" trigger="hover">
                                    <div>
                                        <p>Dataset : 7</p>
                                        <p>CellNumber: 59993</p>
                                        <p>Celltype: B,CD4T,CD8T,Central Venous
                                            LSEC,Cholangiocyte,DC,Endothelial,Epithelial,Erythrocyte,Hepatic
                                            Oval,Hepatocyte,Kupffer,Mast,Mesenchymal,Mono/Macro,Muscle,Neutrophil,NK,Periportal
                                            LSEC,Plasma,Portal Endothelial,Smooth Muscle,Treg</p>

                                    </div>
                                    <router-link slot="reference" to="info_tissue/Liver">
                                        <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                src="@/assets/tissue/Liver.png" alt="">Liver</div>
                                    </router-link>
                                </el-popover>
                            </div>
                            <br>
                            <div id="tissue_one">
                                <el-popover placement="right" width="400" trigger="hover">
                                    <div>
                                        <p>Dataset : 12</p>
                                        <p>CellNumber: 108428</p>
                                        <p>Celltype: Alveolar Bipotent Progenitor,Alveolar Type 1,Alveolar Type
                                            2,B,Basal,CD4T,CD8T,Chondrocyte,Ciliated,Club,DC,Endothelial,Fibroblast,Goblet,Intermediate(Club-Basal),Ionocyte,Lymphatic
                                            Endothelial,Mast,Megakaryocyte,Mono/Macro,Mucous,Muscle,Myofibroblast,Neuroendocrine,Neutrophil,NK,Secretory
                                            Epithelial,Smooth Muscle</p>

                                    </div>
                                    <router-link slot="reference" to="info_tissue/Lung">
                                        <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                src="@/assets/tissue/Lung.png" alt="">Lung</div>
                                    </router-link>
                                </el-popover>
                            </div>
                            <div id="tissue_one">
                                <el-popover placement="right" width="400" trigger="hover">
                                    <div>
                                        <p>Dataset : 1</p>
                                        <p>CellNumber: 7770</p>
                                        <p>Celltype: B,CD4T,CD8T,Mono/Macro,Treg</p>

                                    </div>
                                    <router-link slot="reference" to="info_tissue/Lymph-Node">
                                        <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                src="@/assets/tissue/Lymph-Node.png" alt="">Lymph Node</div>
                                    </router-link>
                                </el-popover>
                            </div>
                            <div id="tissue_one">
                                <el-popover placement="right" width="400" trigger="hover">
                                    <div>
                                        <p>Dataset : 8</p>
                                        <p>CellNumber: 79895</p>
                                        <p>Celltype: Astrocyte,B,CD4T,CD8T,Dopaminergic
                                            Neuron,Endothelial,Ependymal,Epithelial,Excitatory
                                            Neuron,GABAergic
                                            Neuron,Granule,Inhibitory
                                            Neuron,Interneuron,Microglia,Mono/Macro,Muscle,Neutrophil,NK,Oligodendrocyte,OPC,Pericyte,Plasma,Purkinje
                                            Neuron,Treg</p>

                                    </div>
                                    <router-link slot="reference" to="info_tissue/Midbrain">
                                        <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                src="@/assets/tissue/Midbrain.png" alt="">Midbrain</div>
                                    </router-link>
                                </el-popover>

                            </div>
                            <div id="tissue_one">
                                <el-popover placement="right" width="400" trigger="hover">
                                    <div>
                                        <p>Dataset : 4</p>
                                        <p>CellNumber: 33339</p>
                                        <p>Celltype:
                                            Adipocyte,B,CD4T,CD8T,DC,Endothelial,Fibroblast,Macrophage,Mast,Muscle
                                            Progenitor,Myeloid,Neutrophil,NK,PiTendon Progenitor,Satellite,Smooth
                                            Muscle,T,T_Unknown,Tendon Progenitor,Tendon Progenitor Stem</p>

                                    </div>
                                    <router-link slot="reference" to="info_tissue/Muscle">
                                        <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                src="@/assets/tissue/Muscle.png" alt="">Muscle</div>
                                    </router-link>
                                </el-popover>
                            </div>
                            <div id="tissue_one">
                                <el-popover placement="right" width="400" trigger="hover">
                                    <div>
                                        <p>Dataset : 2</p>
                                        <p>CellNumber: 46896</p>
                                        <p>Celltype: B,Basal,Bowman’s Gland,CD4T,CD8T,Club,DC,Fibroblast,Globose
                                            Basal,Goblet,Macrophage,Mast,Monocyte,Multiciliated,Neuron,NK,OEC,Olfactory
                                            Horizontal Basal,Olfactory Microvillar,Pericyte,Plasma,Respiratory
                                            Ciliated,Respiratory Horizontal Basal,Smooth Muscle,Suprabasal,Sustentacular
                                        </p>

                                    </div>
                                    <router-link slot="reference" to="info_tissue/Nose">
                                        <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                src="@/assets/tissue/Nose.png" alt="">Nose</div>
                                    </router-link>
                                </el-popover>
                            </div>
                            <div id="tissue_one">
                                <el-popover placement="right" width="400" trigger="hover">
                                    <div>
                                        <p>Dataset : 3</p>
                                        <p>CellNumber: 52641</p>
                                        <p>Celltype:
                                            B,CD4T,CD8T,Endothelial,Erythrocyte,Fibroblast,Germ,Granulosa,Mono/Macro,NK,Smooth
                                            Muscle,Theca</p>

                                    </div>
                                    <router-link slot="reference" to="info_tissue/Ovary">
                                        <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                src="@/assets/tissue/Ovary.png" alt="">Ovary</div>
                                    </router-link>
                                </el-popover>
                            </div>
                            <br>
                            <div id="tissue_one">
                                <el-popover placement="right" width="400" trigger="hover">
                                    <div>
                                        <p>Dataset : 4</p>
                                        <p>CellNumber: 21812</p>
                                        <p>Celltype:
                                            Acinar,Alpha,APC,Beta,Delta,Duct,Endocrine,Endothelial,Epsilon,Exocrine,Fibroblast,Mast,Mesenchymal,Mono/Macro,Muscle,Pancreatic
                                            Polypeptide,Pancreatic Stellate,Schwann</p>

                                    </div>
                                    <router-link slot="reference" to="info_tissue/Pancreas">
                                        <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                src="@/assets/tissue/Pancreas.png" alt="">Pancreas</div>
                                    </router-link>
                                </el-popover>
                            </div>
                            <div id="tissue_one">
                                <el-popover placement="right" width="400" trigger="hover">
                                    <div>
                                        <p>Dataset : 1</p>
                                        <p>CellNumber : 17198</p>
                                        <p>Celltype : B,CD4T,CD8T,Endothelial,Fibroblast,Mesothelial,Mononuclear
                                            Phagocyte,Myofibroblast</p>

                                    </div>
                                    <router-link slot="reference" to="info_tissue/Peritoneum">
                                        <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                src="@/assets/tissue/Peritoneum.png" alt="">Peritoneum</div>
                                    </router-link>
                                </el-popover>
                            </div>
                            <div id="tissue_one">
                                <el-popover placement="right" width="400" trigger="hover">
                                    <div>
                                        <p>Dataset : 5</p>
                                        <p>CellNumber: 64984</p>
                                        <p>Celltype:
                                            APC,CD4T,Cytotrophoblast,DC,Decidual,Endothelial,Epithelial,Epithelial
                                            Glandular,Erythrocyte,Fibroblast,Granulocyte,Hofbauer,Mono/Macro,Muscle,Neutrophil,Proliferating,Syncytiotrophoblast,Trophoblast,Vascular
                                            Smooth Muscle</p>

                                    </div>
                                    <router-link slot="reference" to="info_tissue/Placenta">
                                        <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                src="@/assets/tissue/Placenta.png" alt="">Placenta</div>
                                    </router-link>
                                </el-popover>
                            </div>
                            <div id="tissue_one">
                                <el-popover placement="right" width="400" trigger="hover">
                                    <div>
                                        <p>Dataset : 1</p>
                                        <p>CellNumber: 9794</p>
                                        <p>Celltype: Fibroblast,Mesothelial,Mono/Macro,Neutrophil,NK</p>

                                    </div>
                                    <router-link slot="reference" to="info_tissue/Pleura">
                                        <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                src="@/assets/tissue/Pleura.png" alt="">Pleura</div>
                                    </router-link>
                                </el-popover>
                            </div>
                            <br>
                            <div id="tissue_one">
                                <el-popover placement="right" width="400" trigger="hover">
                                    <div>
                                        <p>Dataset : 4</p>
                                        <p>CellNumber: 193376</p>
                                        <p>Celltype:
                                            Basal,CD4T,Club,Endothelial,Epithelial,Fibroblast,Hillock,Leukocyte,Luminal,Mono/Macro,Muscle,Neuroendocrine,Neutrophil,NK,Smooth
                                            Muscle,Stress</p>

                                    </div>
                                    <router-link slot="reference" to="info_tissue/Prostate">
                                        <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                src="@/assets/tissue/Prostate.png" alt="">Prostate</div>
                                    </router-link>
                                </el-popover>
                            </div>
                            <div id="tissue_one">
                                <el-popover placement="right" width="400" trigger="hover">
                                    <div>
                                        <p>Dataset : 10</p>
                                        <p>CellNumber: 118799</p>
                                        <p>Celltype: Basal,Bulge,CD4T,CD8T,Chondrocyte,Corneum,DC,Eccrine Duct,Eccrine
                                            Gland,Endothelial,Epidermal Stem,Epidermis,Fibroblast,IFE Basal,IFE
                                            Granular,IFE
                                            Mitotic,IFE
                                            Spinous,Infundibulum,IRS/Cuticle,Isthmus,Keratinocyte,Langerhans,Lymph
                                            Endothelial,Mast,Matrix/Cortex/Medulla,Melanocyte,Mesenchymal,Mono/Macro,Myofibroblast,Neural,NK,ORS
                                            B,Pericyte,Schwann,Sebaceous/Apocrine,Secretory Epithelial,Smooth
                                            Muscle,Spinous,Sweat Gland,Treg,Vasc Endothelial</p>

                                    </div>
                                    <router-link slot="reference" to="info_tissue/Skin">
                                        <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                src="@/assets/tissue/Skin.png" alt="">Skin</div>
                                    </router-link>
                                </el-popover>
                            </div>
                            <div id="tissue_one">
                                <el-popover placement="right" width="400" trigger="hover">
                                    <div>
                                        <p>Dataset : 17</p>
                                        <p>CellNumber : 137194</p>
                                        <p>Celltype :
                                            Absorptive,B,CD4T,CD8T,DC,Endothelial,Enterocyte,Enterocyte
                                            Progenitor,Enteroendocrine,Epithelial,Fibroblast,Glial,Goblet,Goblet
                                            Progenitor,ILC,Intraepithelial
                                            Lymphocyte,M,Mast,Mesenchymal,Mono/Macro,Mucous
                                            Neck,Muscle,Myofibroblast,Neuron,NK,Paneth,Paneth-like,Pericyte,Plasma,Schwann,Smooth
                                            Muscle,Stem,Transit Amplifying,Treg,Tuft</p>

                                    </div>
                                    <router-link slot="reference" to="info_tissue/Small-Intestine">
                                        <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                src="@/assets/tissue/Small-Intestine.png" alt="">Small Intestine</div>
                                    </router-link>
                                </el-popover>
                            </div>
                            <div id="tissue_one">
                                <el-popover placement="right" width="400" trigger="hover">
                                    <div>
                                        <p>Dataset : 2</p>
                                        <p>CellNumber: 19957</p>
                                        <p>Celltype: B,CD4T,CD8T,Endothelial,Erythrocyte,Lymphoid
                                            Progenitor,Mono/Macro,Neutrophil,NK,Plasma,Treg</p>

                                    </div>
                                    <router-link slot="reference" to="info_tissue/Spleen">
                                        <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                src="@/assets/tissue/Spleen.png" alt="">Spleen</div>
                                    </router-link>
                                </el-popover>
                            </div>
                            <div id="tissue_one">
                                <el-popover placement="right" width="400" trigger="hover">
                                    <div>
                                        <p>Dataset : 4</p>
                                        <p>CellNumber: 17808</p>
                                        <p>Celltype:
                                            B,CD4T,CD8T,Chief,Chromaffin,Endothelial,Epithelial,Fibroblast,FibSmo,Gastric
                                            Pit,Granulocyte,Mast,Mono/Macro,Mucous,NK,Parietal,Peptic,Pit Mucosal
                                            Epithelial,Plasma,Smooth Muscle</p>

                                    </div>
                                    <router-link slot="reference" to="info_tissue/Stomach">
                                        <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                src="@/assets/tissue/Stomach.png" alt="">Stomach</div>
                                    </router-link>
                                </el-popover>
                            </div>
                            <div id="tissue_one">
                                <el-popover placement="right" width="400" trigger="hover">
                                    <div>
                                        <p>Dataset : 4</p>
                                        <p>CellNumber : 59857</p>
                                        <p>Celltype : Apical/Narrow,Basal,Clear,Efferent
                                            Duct,Endothelial,Germ,Leydig,Macrophage,Peritubular Myoid
                                            Cell(PTM),Principal,Sertoli,Sperm,Spermatid,Spermatocyte,Spermatogonia,Spermatogonial
                                            Stem cell(SSC),Stromal,Vascular Smooth Muscle</p>

                                    </div>
                                    <router-link slot="reference" to="info_tissue/Testis">
                                        <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                src="@/assets/tissue/Testis.png" alt="">Testis</div>
                                    </router-link>
                                </el-popover>
                            </div>
                            <div id="tissue_one">
                                <el-popover placement="right" width="400" trigger="hover">
                                    <div>
                                        <p>Dataset : 1</p>
                                        <p>CellNumber: 59857</p>
                                        <p>Celltype: Apical/Narrow,Basal,Clear,Efferent
                                            Duct,Endothelial,Germ,Leydig,Mono/Macro,Peritubular
                                            Myoid,Principal,Sertoli,Sperm,Spermatid,Spermatocyte,Spermatogonia,Spermatogonial
                                            Stem,Stromal,Vascular Smooth Muscle</p>

                                    </div>
                                    <router-link slot="reference" to="info_tissue/Thymus">
                                        <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                src="@/assets/tissue/Thymus.png" alt="">Thymus</div>
                                    </router-link>
                                </el-popover>
                            </div>
                            <br>
                            <div id="tissue_one">
                                <el-popover placement="right" width="400" trigger="hover">
                                    <div>
                                        <p>Dataset : 2</p>
                                        <p>CellNumber: 12259</p>
                                        <p>Celltype:
                                            B,CD4T,CD8T,DC,Endothelial,Fibroblast,Mono/Macro,Muscle,Neutrophil,Plasma,Thyrocyte
                                        </p>
                                    </div>
                                    <router-link slot="reference" to="info_tissue/Thyroid">
                                        <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                src="@/assets/tissue/Thyroid.png" alt="">Thyroid</div>
                                    </router-link>
                                </el-popover>
                            </div>
                            <div id="tissue_one">
                                <el-popover placement="right" width="400" trigger="hover">
                                    <div>
                                        <p>Dataset : 1</p>
                                        <p>CellNumber : 1886</p>
                                        <p>Celltype: B,Basal,Fibroblast,Mono/Macro,Muscle,NK,Superficial</p>
                                    </div>
                                    <router-link slot="reference" to="info_tissue/Ureter">
                                        <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                src="@/assets/tissue/Prostate.png" alt="">Ureter</div>
                                    </router-link>
                                </el-popover>
                            </div>
                            <div id="tissue_one">
                                <el-popover placement="right" width="400" trigger="hover">
                                    <div>
                                        <p>Dataset : 1</p>
                                        <p>CellNumber: 77478</p>
                                        <p>Celltype:
                                            CD8T,Endometrial,Endothelial,Epithelial,Fibroblast,Mono/Macro,Muscle,NK,Smooth
                                            Muscle</p>

                                    </div>
                                    <router-link slot="reference" to="info_tissue/Uterus">
                                        <div id="tiss" type="primary" round><img height="60px" width="60px"
                                                src="@/assets/tissue/Uterus.png" alt="">Uterus</div>
                                    </router-link>
                                </el-popover>
                            </div>
                        </div>
                    </div>
                </div>
            </el-card>
        </div>
    </div>
</template>


<script>
import celltype_json from "@/assets/summary_celltype_l2.json"; // 引用
export default {
    data() {
        return {
            // img_src :'@/assets/human6.png?t='+Math.random()
            celltype_list: celltype_json,
            activeNames: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
            viewby: true,
            radio: "1",
        }
    },
    created() {
        console.log('creat')
        // location.reload();
    },
    activated() {
        // location.reload();
        console.log('acti')

        // if (location.href.indexOf("#reloaded") == -1) {
        //     location.href = location.href + "#reloaded";
        //     location.reload();
        // }
    },
    methods: {
        handleChange(val) {
            console.log(val);
            console.log(activeNames)
        },
        submitUpload() {
            this.$refs.upload.submit();
        },
    },
};
</script>
<style scoped>
/* .el-row{
  width: 120%;
} */
.el-col {
    margin: 5px;
}

.el-buttom {
    background-color: rgb(26, 96, 67);
}

.selinalogo {
    height: 200px;
    padding: 10px;
    /* padding-right: 0px;
    padding-bottom: 10px;
    padding-top: 3px; */
}



#search {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.el-divider {
    color: rgb(26, 96, 67);
    background-color: rgb(26, 96, 67);
    font-size: 70px;
}

/* img{
  width:"1760px";
  height:"1200px";
  object-fit: none;
} */
#title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: rgb(26, 96, 67);
    /* color: rgb(55,123,213); */
    width: 70%;
    margin-bottom: 80px;
}

#tissue {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

#body {
    width: 30%;
}

#tissue_list {
    width: 99%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* margin-left: 10%; */
}

#old {
    /* width: 70%; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

#tissue_one {
    width: 8%;
    margin: 1%;
    margin-bottom: 3%;
    /* font-size: 14px; */
}

#system-list {
    width:100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: 0%;
}

#system {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

#tiss {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 0.9vw;
    height: 70px;
    color: rgb(33, 56, 50);

}

#tiss>img {
    width: 38%;
    height: auto;
}

.el-card {
    width: 100%;
}

#cont {
    width: 100%;
}

/* #lisr {
    width: 80%;
} */

.link {
    color: black;
    font-weight: bold;
}

/* CSS link color (red) */
.link:hover {
    color: rgb(3, 138, 81);
}
</style>


